<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope">
        <div id="sticky-heading-container" class="heading-container sticky">
            <div class="back-title-container">
                <BackButton />
                <h1 class="title">{{id ? $t(`pages.locations.update.title`) : $t(`pages.locations.create.title`)}}</h1>
            </div>

            <div class="cta-container">
                <button v-if="loaded" class="cta cta-primary cta-slim" :class="{ loading: loading.create }" @click="create_location()">{{id ? $t('pages.locations.card.update.update') : $t('pages.locations.card.create.create')}}</button>
            </div>
        </div>

        <div id="with-sticky-header" class="location-container with-sticky-header">
            <div class="row">   
                <div class="card card-create-location">
                    <div class="card-body">
                        <div class="question-wrapper">
                            <div class="question location-name column">
                                <div class="label-container">
                                    <p>{{$t('pages.locations.card.create.common.name')}}</p>
                                </div>
                                
                                <input id="input-location-name" :class="['input input-text', { error: validation.name.error }]" v-model="data.name" type="text">
                                <FormError :data="validation.name" />
                            </div>

                            <div class="question address-line-1 column">
                                <div class="label-container">
                                    <p>{{$t('pages.locations.card.create.common.addressLine1')}}</p>
                                </div>

                                <input ref="autocomplete" 
                                    id="autocomplete-input"
                                    :placeholder="$t('getstarted.steps.two.workProvider.splits.two.search.placeholder')" 
                                    :class="['input input-text', error('addressLine1')]"
                                    onfocus="value = ''"
                                    @click="clear()"
                                    type="text" />
                                
                                <FormError :data="validation.addressLine1" />
                            </div>

                            <div class="question address-line-2 column">
                                <div class="label-container">
                                    <p>{{$t('pages.locations.card.create.common.addressLine2')}}</p>
                                </div>

                                <input id="input-location-addressLine2" :class="['input input-text']" v-model="data.addressLine2" type="text">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div v-if="loaded" class="card card-validate-address">
                    <div class="card-body"> 
                        <iframe
                            width="100%"
                            height="100%"
                            frameborder="0" style="border:0"
                            referrerpolicy="no-referrer-when-downgrade"
                            :src="preview_src"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"
import { bus } from '../../../main';

import Layout from '../../../components/Layout.vue';
import FormError from '../../../components/Utils/FormError.vue'
import BackButton from '../../../components/Navigation/BackButton.vue';

export default {
    name: 'Location',

    components: {
        Layout,
        FormError,
        BackButton
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        },

        type: {
            type: String,
            default: 'work-provider'
        },

        id: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            routeName: 'location',
            classes: { dashboard: true, location: true },

            data: {
                name: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                postalCode: '',

                country: '',
                province: '',

                geocoding: {},
            },

            search_query: '',

            validation: {
                name: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },
                
                addressLine1: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },
                
                city: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                postalCode: {
                    error: false,
                    key: 'errors.validation.postalCodeFormatInvalid'
                },

                province: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },

                country: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                },
            },

            formValidated: false,
            loading: {
                create: false
            },
        }
    },

    computed: {
        preview_src: function() {
            const self = this;
            
            if(self.loaded) {
                return `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_EMBEDED_API_KEY}&q=${self.preview_query}`
            }

            return ''
        },

        preview_query: function() {
            const self = this;
            return `${self.data.addressLine1} ${self.data.city} ${self.data.province} ${self.data.postalCode} ${self.data.country}`
        },

        loaded: function() {
            const self = this;
            return self.data.addressLine1 && self.data.city && self.data.province && self.data.postalCode && self.data.country
        }
    },

    methods: {
        clear: function() {
            const self = this;
            
            self.data.addressLine1 = '';
            self.data.city = '';
            self.data.province = '';
            self.data.postalCode = '';
            self.data.country = '';
            self.data.geocoding = {};
        },

        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            Object.keys(self.validation).forEach(val => {
                self.validation[val].error = false
                self.validation[val].key = 'errors.validation.fieldMandatory';
                if(val === 'postalCode') { self.validation[val].key = 'errors.validation.postalCodeFormatInvalid' }
            });

            ['name', 'addressLine1', 'city', 'province', 'country'].forEach(key => {
                if(!self.data[key] || self.data[key] === '')
                    self.validation[key].error = true;
            });

            if(!self.$utils._validate_postal_code_format(self.data.postalCode))
                self.validation.postalCode.error = true;

            Object.keys(self.validation).forEach(val => {
                if(self.validation[val].error) {
                    self.formValidated = false
                }
            });
        },

        create_location: function() {
            const self = this;

            self.validate_mandatory_fields(); 
            if(self.formValidated) {
                self.loading.create = true;

                const method = self.id ? 'put' : 'post'
                
                const data = {
                    ...self.data,
                    ownerType: 'workProvider'
                }

                self.$axios[method](`/location`, data)
                    .then(response => {
                        if(response.status == 200) {
                            self.loading.create = false;
                            self.$nextTick(() => {
                                if(!self.id)
                                    if(self.$route && self.$route.query && self.$route.query.redirect) {
                                        self.$utils._navigate_to_path_with_query(self.$route.query.redirect, { scroll_back: true });
                                    } else {
                                        self.$utils._navigate_to_name_with_params('locations', { type: 'work-provider' })
                                    }
                                else {
                                    self.loading.create = false;
                                    self.$toasted.success(
                                        self.$t('notifications.locationUpdateSuccess'),
                                        { icon: 'circle-check' }
                                    );
                                }
                            })
                        }
                    })
                    .catch(error => {
                        self.loading.create = false;
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                    });
            } else {
                console.log('all fields are mandatory');
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },
        
        get_location: function() {
            const self = this;

            self.$axios
                .get(`location/work-provider/${self.id}`)
                .then(response => {
                    if(response.status == 200) {
                        self.data = response.data;
                        self.data.geocoding = {
                            formattedAddress: response.data.formattedAddress,
                            geolocation: {
                                longitude: response.data.geolocation.coordinates[0],
                                latitude: response.data.geolocation.coordinates[1]
                            }
                        }

                        this.$refs.autocomplete.value = response.data.formattedAddress;

                        self.$nextTick(() => {
                            bus.$emit('reset_heading_container_margin', true);
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(e);
                console.log(key);
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        },

        format_autocomplete_response: function(place) {
            const self = this;

            const addressLine1 = place.address_components.find(component => component.types.includes('street_number')).long_name + ' ' + place.address_components.find(component => component.types.includes('route')).long_name;
            const city = place.address_components.find(component => component.types.includes('locality')).long_name;
            const province = place.address_components.find(component => component.types.includes('administrative_area_level_1')).short_name;
            const postalCode = place.address_components.find(component => component.types.includes('postal_code')).long_name;
            const country = place.address_components.find(component => component.types.includes('country')).long_name;
            
            self.search_query = place.formatted_address;

            self.data.addressLine1 = addressLine1;
            self.data.city = city;
            self.data.province = province;
            self.data.postalCode = postalCode;
            self.data.country = country;
            self.data.geocoding = {
                formattedAddress: place.formatted_address,
                geolocation: {
                    latitude: place.geometry.location.lat(),
                    longitude: place.geometry.location.lng()
                }
            }
        },

        setup_autocomplete: async function() {
            const self = this;
            const loader = new Loader({
                apiKey: process.env.VUE_APP_MAPS_API_KEY,
                version: "weekly",
            });

            let center = { lat: 46.849132, lng: -71.255024 };
            if(self.users_geolocation && self.users_geolocation.lat && self.users_geolocation.long) {
                center = { lat: self.users_geolocation.lat, lng: self.users_geolocation.long };
            }

            const defaultBounds = {
                north: center.lat + 0.3,
                south: center.lat - 0.3,
                east: center.lng + 0.3,
                west: center.lng - 0.3,
            };

            const autocomplete_options = {
                types: ['geocode'],
                componentRestrictions: { country: 'ca' },
                strictBounds: false,
                bounds: defaultBounds
            };

            loader.importLibrary('places')
                .then(({Autocomplete}) => {
                    const autocomplete = new Autocomplete(
                        (this.$refs.autocomplete),
                        autocomplete_options
                    );

                    autocomplete.addListener('place_changed', () => {
                        self.format_autocomplete_response(autocomplete.getPlace());
                    });
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },

    mounted() {
        const self = this; 
        self.setup_autocomplete();

        if(self.id) { 
            self.get_location() 
        } else {
            self.$nextTick(() => {
                bus.$emit('reset_heading_container_margin', true);
            })
        }
    }
}
</script>

